.page-stories {
  margin: 20px;
  max-width: 75%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-content: space-between;
  justify-content: space-between;
  margin-bottom: 20px;
}

.story a.view:before {
  content: ' | ';
}

.publishedStories,
.unpublishedStories {
  margin-bottom: 50px;
}

.publishedStories ul,
.unpublishedStories ul {
  text-indent: 10px;
}

.publishedStories h3,
.unpublishedStories h3 {
  border-bottom: 1px solid whitesmoke;
}
