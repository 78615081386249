.scene {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.main {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.content {
  order: 1;
  width: 100%;
  flex: 3 1 auto;
}

.sidebar {
  order: 0;
  flex: 1 1 auto;
  margin-right: 10px;
}
