body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
}
